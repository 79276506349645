<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pa-4">
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="categories"
                        class="elevation-1 mt-4"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.enabled`]="{ item }">
                            <v-switch
                                v-model="item.enabled"
                                class="pa-0 ma-0"
                                @change="update(item)"
                                hide-details
                            />
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon color="primary" @click="edit(item)">mdi-pencil</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Категория
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editCategory.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editCategory.external_id"
                                    outlined
                                    label="Внешний ID"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="editCategory.position"
                                    outlined
                                    label="Позиция"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editCategory.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editCategory.for_delivery"
                                    label="Показывать в разделе доставки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="editCategory.for_menu"
                                    label="Показывать в электронном меню"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editCategory.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    export default {
        name: 'SiteCategories',

        components: {
            SiteTabLinks,
        },

        data() {
            return {
                loading: true,
                headers: [
                    { text: 'Позиция', value: 'position' },
                    { text: 'Активен', value: 'enabled' },
                    { text: 'Название', value: 'title' },
                    { text: '', align: 'right', value: 'edit' },
                ],
                editModal: false,
                editCategory: {},
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            }
        },

        async mounted() {
            this.loading = true;
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('sites/get', {id});
                await store.dispatch('categories/fetch', {site_id: id});
            }
            this.loading = false;
        },

        methods: {
            create() {
                this.editCategory = {
                    site_id: this.$route.params.id,
                    position: (this.categories.length + 1) * 10,
                    enabled: true,
                    for_delivery: true,
                    for_menu: true
                };
                this.editModal = true;
            },
            edit(item) {
                this.editCategory = item;
                this.editModal = true;
            },
            async save() {
                try {
                    store.commit('categories/SET_ENTITY', this.editCategory);
                    await store.dispatch('categories/save', {site_id: this.$route.params.id});
                    await store.dispatch('categories/fetch', {site_id: this.$route.params.id});
                    this.editModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async update(category) {
                await store.dispatch('categories/update', { site_id: this.$route.params.id, category });
            },
            async remove() {
                try {
                    await store.dispatch('categories/delete', {site_id: this.$route.params.id, id: this.editCategory.id});
                    await store.dispatch('categories/fetch', {site_id: this.$route.params.id});
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${id}/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>